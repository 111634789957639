import { Ruler } from '@ClientDto/Ruler';
import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: '[ruler]',
  standalone: true,
  imports: [NgIf],
  templateUrl: './ruler.component.html',
  styleUrl: './ruler.component.scss',
})
export class RulerComponent {
  public readonly serifSize: number = 20;

  @Input()
  public ruler!: Ruler;

  get textX() {
    return this.ruler.length / 2;
  }

  get textY() {
    if (this.ruler.moveNumberDisplay) {
      return 40;
    } else {
      return -5;
    }
  }
}
