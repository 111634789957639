import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { BaseSnapService } from 'app/ts/services/snap/BaseSnapService';
import { ISnapInfoService } from 'app/ts/services/snap/ISnapInfoService';
import { SnapUnderService } from 'app/ts/services/snap/SnapUnderService';
import { ConfigurationItemHelper } from 'app/ts/util/ConfigurationItemHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { InteriorLogic } from 'app/ts/services/ConfigurationLogic/InteriorLogic';
export class SnapAboveGableService extends BaseSnapService<SnapUnderService.SnapInfo> {
  static supportsItems(items: Client.ConfigurationItem[]): boolean {
    return items.length === 1 && items[0].snapAboveGables;
  }

  private readonly possiblePositions: SnapAboveGableService.PossiblePositionInfo[];
  private readonly otherItemCubes: Client.ItemCube[];

  constructor(
    cabinetSection: Client.CabinetSection,
    item: Client.ConfigurationItem,
    dragStartPoint: Interface_DTO_Draw.Vec2d,
    fallbackSnapService: ISnapInfoService,
  ) {
    super(cabinetSection, item, dragStartPoint, fallbackSnapService);
    this.otherItemCubes = cabinetSection.interior.itemCubes.filter(
      (cube) => cube.item !== item,
    );
    if (cabinetSection.isSwing) {
      this.otherItemCubes.push(
        ...cabinetSection.swing.items
          .filter(
            (swingItem) =>
              swingItem.ItemType === Interface_Enums.ItemType.SwingCorpus,
          )
          .map(ConfigurationItemHelper.getItemCube),
      );
    }
    this.possiblePositions = this.getPossiblePositions();
  }

  protected overriddenGetSnapInfo(
    pos: Interface_DTO_Draw.Vec2d,
  ): Client.SnapInfo | undefined {
    let mouseOffset = VectorHelper.subtract(pos, this.dragStartPoint);
    let rawItemPos = VectorHelper.add(mouseOffset, this.item);

    let sortedPositions = this.possiblePositions.sort(
      (p1, p2) =>
        VectorHelper.dist(rawItemPos, p1) - VectorHelper.dist(rawItemPos, p2),
    );
    let bestPosition = sortedPositions[0];

    if (
      !bestPosition ||
      VectorHelper.dist(pos, bestPosition) > Constants.snapDist * 5
    )
      return;

    let snapsTo: Client.ConfigurationItem[] = [];
    for (let gable of [
      bestPosition.gableGap.leftGable,
      bestPosition.gableGap.rightGable,
    ])
      if (!!gable) snapsTo.push(gable);

    let result: Client.SnapInfo = {
      dropOffset: VectorHelper.subtract(bestPosition, this.item),
      pulloutRestriction: Enums.PulloutWarningSeverity.None,
      rulers: bestPosition.rulers,
      suggestedItemOffsets: [],
      newSize: {
        X: bestPosition.Width,
        Y: bestPosition.Height,
        Z: bestPosition.Depth,
      },
      collisionAreas: bestPosition.collisionAreas,
      horizontalGuidelineHeights: [],
      snapsTo: snapsTo,
      snappingItems: [this.item],
    };
    return result;
  }

  private getPossiblePositions(): SnapAboveGableService.PossiblePositionInfo[] {
    let result: SnapAboveGableService.PossiblePositionInfo[] = [];

    let gableGaps = InteriorLogic.getGableGaps(this.cabinetSection, {
      excludeItems: [this.item],
    });
    let allowAllProducts =
      this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
    let allowAllMaterials =
      this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;
    // Only gaps with module gables on both sides are suitable for this item
    let suitableGaps = gableGaps.filter(
      (gap) =>
        this.item.Product!.isAvailableInWidth(
          gap.Width,
          allowAllProducts,
          this.cabinetSection.cabinet.ProductLineId,
          allowAllMaterials,
          this.item.MaterialId,
        ) &&
        gap.leftGable &&
        gap.leftGable.drilling600Right &&
        gap.rightGable &&
        gap.rightGable.drilling600Left,
    );

    // The item must snap on top of support gable(s)
    let supportGables = Enumerable.from(
      this.cabinetSection.interior.items.filter((i) => i.snapFront),
    ).orderBy((g) => g.X);

    for (let gap of suitableGaps) {
      let supportGable = supportGables.firstOrDefault(
        (g) => g.centerX > gap.X && g.centerX < gap.X + gap.Width,
      );
      if (!supportGable) {
        continue;
      }

      let otherItemsInLane = (
        this.otherItemCubes as Interface_DTO_Draw.Rectangle[]
      ).filter((otherItemRect) => VectorHelper.overlapsX(otherItemRect, gap));

      otherItemsInLane.push(...this.cabinetSection.interior.boundingBoxes);

      let backZ = gap.leftGable!.Z;
      let frontZ = gap.leftGable!.frontZ;

      let optimalDepth = frontZ - backZ - this.item.depthReduction;
      let depth = ObjectHelper.clamp(
        this.item.minDepth,
        optimalDepth,
        this.item.maxDepth,
      );

      let z = frontZ - this.item.depthReduction - depth;

      for (let drillStop of gap.drillStops) {
        let candCube: Interface_DTO_Draw.Cube = {
          X: gap.X,
          Y: drillStop - this.item.snapOffsetY,
          Z: z,
          Width: gap.Width,
          Height: this.item.Height,
          Depth: depth,
        };
        if (
          Math.abs(candCube.Y - supportGable.topY) >
          Constants.sizeAndPositionTolerance
        ) {
          continue;
        }

        let overlap = otherItemsInLane.some((oir) =>
          VectorHelper.overlaps(candCube, oir),
        );
        if (overlap) {
          continue;
        }

        let otherItemsAbove = otherItemsInLane
          .filter((otherItem) => otherItem.Y >= candCube.Y + candCube.Height)
          .sort((r1, r2) => r1.Y - r2.Y);
        let itemAbove = otherItemsAbove[0];

        let collisionAreas = InteriorLogic.getCollidingCollisionAreas(
          this.item,
          false,
          this.otherItemCubes,
          candCube,
        );
        let rulers = this.getRulers(candCube, itemAbove);

        let candidate: SnapAboveGableService.PossiblePositionInfo = {
          ...candCube,
          itemAbove: itemAbove,
          collisionAreas: collisionAreas,
          gableGap: gap,
          rulers: rulers,
        };

        result.push(candidate);
      }
    }
    if (App.debug.showSnapInfo)
      console.debug('SnapAboveGableService, possible snap points: ', result);

    return result;
  }

  private getRulers(
    candCube: Interface_DTO_Draw.Rectangle,
    itemAbove: Interface_DTO_Draw.Rectangle,
  ): Client.Ruler[] {
    let rulers = [];
    let rulerX = candCube.X + candCube.Width / 2;

    let distanceAbove =
      (itemAbove ? itemAbove.Y : this.cabinetSection.Height) -
      candCube.Y -
      candCube.Height;
    let distToAboveRuler = new Client.Ruler(
      true,
      {
        X: rulerX,
        Y: candCube.Y + candCube.Height,
      },
      distanceAbove,
      this.cabinetSection.Height,
      false,
      true, // moveNumberDisplay should be true for items above the cursor, false below
    );
    rulers.push(distToAboveRuler);

    let widthRuler = new Client.Ruler(
      false,
      {
        X: candCube.X,
        Y: candCube.Y + candCube.Height + Constants.rulerWidth / 4,
      },
      candCube.Width,
      this.cabinetSection.Height,
      false,
    );
    rulers.push(widthRuler);

    return rulers;
  }

  protected overriddenPlace(
    lastSnapInfo: SnapUnderService.SnapInfo,
  ): [Client.ConfigurationItem] {
    //do nothing, base class handles everything
    return [this.item];
  }
}

module SnapAboveGableService {
  export interface PossiblePositionInfo extends Interface_DTO_Draw.Cube {
    itemAbove: Interface_DTO_Draw.Rectangle | undefined;
    collisionAreas: Client.CollisionArea[];
    gableGap: Client.GableGap;
    rulers: Client.Ruler[];
  }
}
