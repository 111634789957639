import { Component, Input, OnInit } from '@angular/core';
import * as Enums from 'app/ts/clientDto/Enums';
import * as Client from 'app/ts/clientDto/index';
import { Constants } from 'app/ts/Constants';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import * as BrowserHelper from 'app/ts/util/BrowserHelper';
import { CabinetSectionHelper } from 'app/ts/util/CabinetSectionHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { ProperSvgElement } from 'app/ts/util/ProperSvgElement';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import { BehaviorSubject } from 'rxjs';
import { Interior2dVm } from './Interior2dVm';

@Component({
  selector: 'door-image',
  templateUrl: './doorImage.html',
  styleUrls: ['../../style/d2.scss'],
})
export class DoorImageVm extends BaseVm implements OnInit {
  private static readonly padding = 50;
  private static readonly topDownPadding = 120;

  constructor(
    baseVmService: BaseVmService,
    private readonly floorPlanService: FloorPlanService,
  ) {
    super(baseVmService);
    this.foreignObjectSupported = BrowserHelper.supportsSvgForeignObject();
  }

  private cache: Partial<{
    corpusItems: Client.ConfigurationItem[];
    corpusJoints: Interior2dVm.Joint[];
  }> = {};

  private clearCache() {
    this.cache = {};
  }

  @Input()
  public cabinetSection!: Client.CabinetSection;
  @Input()
  public showPullout: boolean = false;
  @Input()
  public showRulers: boolean = false;
  @Input()
  public showTopDown: boolean = true;

  @Input()
  public selectedPart$? = new BehaviorSubject<
    Client.DoorFilling | Client.DoorBar | Client.DoorOverlap | null
  >(null);

  public rulers: Client.Ruler[] = [];
  public foreignObjectSupported = false;

  public ngOnInit() {
    if (this.cabinetSection) {
      this.subscribeTo(
        this.cabinetSection.cabinet.floorPlan.floorPlan$,
        (fp) => {
          this.createRulers();
          this.clearCache();
        },
      );
    }
    this.svgCanvas = window.document.getElementById('door-image') as any;
    this.svgMousePoint = this.svgCanvas.createSVGPoint();
  }

  private createRulers() {
    let rulersDict = Client.Ruler.GetRulers(this.cabinetSection);
    this.rulers = [
      rulersDict.cabinetWidthRuler,
      rulersDict.cabinetSightWidthRuler,
      rulersDict.cabinetHeightRuler,
      rulersDict.cabinetSightHeightRuler,
    ];

    if (rulersDict.extraRailWidthLeftRuler.length > 0) {
      this.rulers.push(rulersDict.extraRailWidthLeftRuler);
    }
    if (
      rulersDict.extraRailWidthRightRuler.length > 0 &&
      !this.cabinetSection.doors.onlyDoor
    ) {
      this.rulers.push(rulersDict.extraRailWidthRightRuler);
    }

    // Vertical bars
    let verticalBarRulerPosY = undefined;
    for (let door of this.cabinetSection.doors.doors) {
      if (door.verticalBars.length > 0) {
        if (!verticalBarRulerPosY) {
          verticalBarRulerPosY =
            door.height - (door.verticalBars[0].positionY + 50);
        }

        let leftX = door.frameWidthLeft;
        let rightX = leftX;

        for (let vertBar of door.verticalBars) {
          rightX = vertBar.positionX;

          let ruler = new Client.Ruler(
            false,
            { X: door.leftX + leftX, Y: verticalBarRulerPosY },
            rightX - leftX,
            this.cabinetSection.Height,
            false,
          );
          this.rulers.push(ruler);

          leftX = vertBar.rightX;
        }

        rightX = door.width - door.frameWidthRight;

        let ruler = new Client.Ruler(
          false,
          { X: door.leftX + leftX, Y: verticalBarRulerPosY },
          rightX - leftX,
          this.cabinetSection.Height,
          false,
        );
        this.rulers.push(ruler);
      }
    }
  }

  public get topDownPosX() {
    return 0;
  }
  public get topDownPosY() {
    return (
      this.cabinetSection.Height +
      DoorImageVm.topDownPadding -
      this.cabinetSection.interior.cube.Depth -
      this.cabinetSection.interior.cube.Z
    );
  }

  public get doors() {
    return this.cabinetSection ? this.cabinetSection.doors.doors : [];
  }
  public get doorsOrderedByPositionZ() {
    if (this.cabinetSection == null) return [];
    const sortedArray = [...this.cabinetSection.doors.doors];

    return sortedArray.sort((a, b) => {
      if (a.position.Z === b.position.Z) {
        return 0;
      }
      return a.position.Z < b.position.Z ? -1 : 1;
    });
  }

  public get cornerMoulding() {
    return this.cabinetSection
      ? this.cabinetSection.doors.cornerMoulding
      : null;
  }

  public get viewBoxPosition(): Interface_DTO_Draw.Vec2d {
    let result = {
      X: -DoorImageVm.padding,
      Y: -DoorImageVm.padding,
    };

    result.X -=
      this.cabinetSection.doors.numberOfRailTracks > 1
        ? this.cabinetSection.doors.extraRailWidthLeft
        : 0;

    result.X -= this.rulerSpacing.X;
    result.Y -= this.rulerSpacing.Y;

    return result;
  }

  public roundUp(n: number): number {
    return Math.ceil(n);
  }

  public getLabel(filling: Client.DoorFilling): string {
    if (!filling.material) {
      return this.translate(
        'door_filling_unknown_material',
        'Unknown material',
      );
    }
    return filling.material.Number + ' - ' + filling.material.DefaultName;
  }

  public getLabelWidth(filling: Client.DoorFilling): number {
    let lengthPerChar = 22;
    let margin = 56;
    return this.getLabel(filling).length * lengthPerChar + margin;
  }

  private get rulerSpacing(): Interface_DTO_Draw.Vec2d {
    return this.showRulers
      ? { X: 2 * Constants.rulerWidth, Y: 2 * Constants.rulerWidth }
      : { X: 0, Y: 0 };
  }

  public get viewBoxSize(): Interface_DTO_Draw.Vec2d {
    let result = {
      X: this.cabinetSection.Width,
      Y: this.cabinetSection.Height,
    };

    result.X +=
      this.cabinetSection.doors.numberOfRailTracks > 1
        ? this.cabinetSection.doors.extraRailWidthLeft
        : 0;
    result.X +=
      this.cabinetSection.doors.numberOfRailTracks > 1
        ? this.cabinetSection.doors.extraRailWidthRight
        : 0;

    result.X += this.rulerSpacing.X;
    result.Y += this.rulerSpacing.Y;

    result.X += 2 * DoorImageVm.padding;
    result.Y += 2 * DoorImageVm.padding;

    if (this.showTopDown) {
      result.Y += 2 * DoorImageVm.topDownPadding;
      result.Y +=
        this.cabinetSection.Depth -
        this.cabinetSection.interior.cube.Depth -
        this.cabinetSection.interior.cube.Z;
    }

    return result;
  }

  public get viewBoxString(): string {
    let pos = this.viewBoxPosition;
    let size = this.viewBoxSize;
    return pos.X + ' ' + pos.Y + ' ' + size.X + ' ' + size.Y;
  }

  public get sectionDepth(): number {
    return this.cabinetSection ? this.cabinetSection.Depth : 0;
  }
  public get railDepth(): number {
    return this.cabinetSection
      ? CabinetSectionHelper.getRailSetDepth(this.cabinetSection, true)
      : 0;
  }

  public get selectedFilling(): Client.DoorFilling | null {
    if (!this.selectedPart$) {
      return null;
    }
    let part = this.selectedPart$.value;
    if (part && part.type === Client.DoorFilling.type) return part;
    return null;
  }

  public set selectedFilling(val: Client.DoorFilling | null) {
    if (this.selectedPart$) this.selectedPart$.next(val);
  }

  public get selectedBar(): Client.DoorBar | null {
    if (!this.selectedPart$) return null;
    let part = this.selectedPart$.value;
    if (part && part.type === Client.DoorBar.type) return part;
    return null;
  }
  public set selectedBar(val: Client.DoorBar | null) {
    if (this.selectedPart$) this.selectedPart$.next(val);
  }

  public get selectedOverlap(): Client.DoorOverlap | null {
    if (!this.selectedPart$) return null;
    let part = this.selectedPart$.value;
    if (part && part.type === Client.DoorOverlap.type) return part;
    return null;
  }
  public set selectedOverlap(val: Client.DoorOverlap | null) {
    if (this.selectedPart$) this.selectedPart$.next(val);
  }

  public getItemTransformString(item: Client.ConfigurationItem): string {
    let result = '';
    let x = item.X;
    let y = this.cabinetSection.Height - item.Y - item.Height;
    result += ' translate( ' + x + ' , ' + y + ' ) ';
    return result;
  }

  public getFillingTransformString(
    door: Client.Door,
    filling: Client.DoorFilling,
  ) {
    let result = 'scale( ' + filling.width + ' ' + filling.height + ' )';
    if (filling.isDesignFilling) {
      result += ' rotate( 90 ) translate( 0 -1 )';
    }
    return result;
  }

  public getFillingImagePath(
    door: Client.Door,
    filling: Client.DoorFilling,
  ): string {
    if (!filling) return '';
    if (!filling.material) return '';
    if (this.useCorrectlySizedMaterials(door, filling)) {
      return filling.material.TexturePath;
    }

    if (!filling.material.ImagePath) return '';
    return filling.material.ImagePath;
  }

  public useCorrectlySizedMaterials(
    door: Client.Door,
    filling: Client.DoorFilling,
  ): boolean {
    if (!filling.material) return false;
    if (!filling.material.TexturePath) return false;
    if (!filling.material.TextureWidthMm) return false;
    if (!filling.material.TextureHeightMm) return false;
    return true;
  }

  public getFillingPatternId(
    door: Client.Door,
    filling: Client.DoorFilling,
  ): string {
    return 'filling_pattern_' + door.index + '_' + filling.index;
  }

  public getTextureUrl(material: Client.ProductMaterial) {
    if (!material.TexturePath) return '';
    return (
      '/StaticAssets/images/Materials2/' + material.TexturePath + '/map.jpg'
    );
  }

  public async setChanged() {
    await this.floorPlanService.setChanged(
      this.cabinetSection.cabinet.floorPlan,
    );
  }

  public get corpusItems() {
    if (!this.cache.corpusItems) {
      this.cache.corpusItems = this.cabinetSection.corpus.allCorpusItems;
    }
    return this.cache.corpusItems;
  }

  public get corpusJoints(): Interior2dVm.Joint[] {
    if (!this.cache.corpusJoints) {
      let topJoints = this.cabinetSection.corpus.jointsTop.map((j) => {
        let height = Math.max(
          0,
          ...this.cabinetSection.corpus.itemsTop.map((i) => i.Height),
        );
        return <Interior2dVm.Joint>{
          X: j + this.cabinetSection.corpus.topDeductionLeft,
          Y: this.cabinetSection.Height - height,
          height: height,
        };
      });
      let bottomJoints = this.cabinetSection.corpus.jointsBottom.map((j) => {
        let height = Math.max(
          0,
          ...this.cabinetSection.corpus.itemsBottom.map((i) => i.Height),
        );
        return <Interior2dVm.Joint>{
          X: j + this.cabinetSection.corpus.bottomDeductionLeft,
          Y: 0,
          height: height,
        };
      });
      this.cache.corpusJoints = topJoints.concat(...bottomJoints);
    }
    return this.cache.corpusJoints;
  }

  public profileImageTransform(profile: Client.DoorProfile): string {
    if (
      profile.type === Enums.DoorProfilePosition.Bottom ||
      profile.type === Enums.DoorProfilePosition.Top
    ) {
      return (
        'translate( 0 ' +
        profile.size.Y +
        ' ) scale( ' +
        -profile.size.X +
        ' ' +
        -profile.size.Y +
        ' ) rotate( 90 ) '
      );
    } else {
      return 'scale( ' + profile.size.X + ' ' + profile.size.Y + ' ) ';
    }
  }

  //#region doorBar dragDrop

  private svgMousePoint!: SVGPoint;
  private svgCanvas!: ProperSvgElement;
  private static minDragStartDist = 30;

  public dragInfo: {
    type: 'dragBar';
    bar: Client.DoorBar;
    dragStarted: boolean;
    initialPos: Interface_DTO_Draw.Vec2d;
    mouseHeightOffset: number;
    currentDisplayPos: Interface_DTO_Draw.Rectangle;
    targetBarPos: number;
  } | null = null;

  public startDrag(evt: MouseEvent | TouchEvent, bar: Client.DoorBar) {
    let clickPoint = this.getDomainPosition(evt);
    let offset = clickPoint.Y - bar.positionAbsolute;
    this.dragInfo = {
      type: 'dragBar',
      bar: bar,
      dragStarted: false,
      initialPos: clickPoint,
      mouseHeightOffset: offset,
      targetBarPos: 0,
      currentDisplayPos: {
        X: bar.door.position.X + bar.door.frameWidthLeft,
        Y:
          this.cabinetSection.Height -
          bar.positionAbsolute -
          bar.height -
          offset,
        Width: bar.width,
        Height: bar.height,
      },
    };
    evt.stopPropagation();
    evt.preventDefault();
  }

  public mouseMove(evt: MouseEvent | TouchEvent): boolean {
    if (!this.dragInfo) return false;
    let currentPos = this.getDomainPosition(evt);
    if (!this.dragInfo.dragStarted) {
      let dist = VectorHelper.dist(currentPos, this.dragInfo.initialPos);
      if (dist < DoorImageVm.minDragStartDist) {
        return false;
      }
      this.dragInfo.dragStarted = true;
    }
    let bar = this.dragInfo.bar;
    let doorOffsetY = bar.positionAbsolute - bar.position;

    let currentBarPos = ObjectHelper.clamp(
      bar.positionMin,
      currentPos.Y - this.dragInfo.mouseHeightOffset - doorOffsetY,
      bar.positionMax,
    );

    this.dragInfo.targetBarPos = currentBarPos;

    this.dragInfo.currentDisplayPos.Y =
      this.cabinetSection.Height -
      currentBarPos -
      doorOffsetY -
      this.dragInfo.currentDisplayPos.Height;

    evt.stopPropagation();
    evt.preventDefault();
    return true;
  }

  public mouseUp(evt: MouseEvent | TouchEvent) {
    this.mouseMove(evt);
    if (!this.dragInfo) return;
    if (this.dragInfo.type !== 'dragBar') return;
    if (!this.dragInfo.dragStarted) {
      this.dragInfo = null;
      return;
    }
    this.dragInfo.bar.desiredPosition = this.dragInfo.targetBarPos;
    this.dragInfo = null;
    this.setChanged();
    evt.stopPropagation();
    evt.preventDefault();
  }

  private getDomainPosition(
    evt: MouseEvent | TouchEvent,
  ): Interface_DTO_Draw.Vec2d {
    let clientX: number;
    let clientY: number;
    if (evt instanceof MouseEvent) {
      clientX = evt.clientX;
      clientY = evt.clientY;
    } else if (evt instanceof TouchEvent) {
      clientX = evt.changedTouches[0].clientX;
      clientY = evt.changedTouches[0].clientY;
    } else {
      throw new Error('unknown event type');
    }

    this.svgMousePoint.x = clientX;
    this.svgMousePoint.y = clientY;
    let pt = this.svgMousePoint.matrixTransform(
      this.svgCanvas.getScreenCTM().inverse(),
    );
    let result = {
      X: pt.x,
      Y: this.cabinetSection.Height - pt.y,
    };

    return result;
  }

  public ceil = Math.ceil;

  //#endregion doorBar dragDrop
}
