<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  class="cabinet-section-2d cabinet-section-interior"
  id="interior2d"
  preserveAspectRatio="xMinYMin"
  [attr.viewBox]="canvasViewBoxString"
  (mousemove)="mouseMove($event)"
  (mouseup)="mouseUp($event)"
  (mousedown)="backgroundMouseDown($event)"
  (touchmove)="mouseMove($event)"
  (touchend)="mouseUp($event)"
  (touchstart)="backgroundMouseDown($event)"
>
  <defs>
    <pattern
      id="freeSpaceFill"
      patternUnits="userSpaceOnUse"
      width="24"
      height="24"
    >
      <path
        style="stroke: black; stroke-width: 2"
        d="M-2,2 l4,-4
                       M0,24 l24,-24
                       M22,26 l4,-4"
      />
    </pattern>
    <filter id="dropshadow" height="130%">
      <feGaussianBlur in="SourceAlpha" stdDeviation="15" />
      <!-- stdDeviation is how much to blur -->
      <feOffset dx="20" dy="20" result="offsetblur" />
      <!-- how much to offset -->
      <feComponentTransfer>
        <feFuncA type="linear" slope="0.3" />
        <!-- slope is the opacity of the shadow -->
      </feComponentTransfer>
      <feMerge>
        <feMergeNode />
        <!-- this contains the offset blurred image -->
        <feMergeNode in="SourceGraphic" />
        <!-- this contains the element that the filter is applied to -->
      </feMerge>
    </filter>
  </defs>

  <g class="ruler-view" *ngIf="showRulers">
    <g *ngFor="let ruler of rulers">
      <g
        class="ruler vertical-ruler"
        *ngIf="ruler.isVertical"
        attr.transform="translate( {{ ruler.svgStart.X }} {{
          ruler.svgStart.Y
        }} )"
      >
        <line
          class="ruler-line ruler-serif"
          style="stroke: black; stroke-width: 3px"
          [attr.x1]="-ruler.serifSize"
          [attr.x2]="ruler.serifSize"
          y1="0"
          y2="0"
        />
        <line
          class="ruler-line ruler-serif"
          style="stroke: black; stroke-width: 3px"
          [attr.x1]="-ruler.serifSize"
          [attr.x2]="ruler.serifSize"
          [attr.y1]="-ruler.length"
          [attr.y2]="-ruler.length"
        />
        <line
          class="ruler-line ruler-main-line"
          style="stroke: black; stroke-width: 3px"
          y1="0"
          [attr.y2]="-ruler.length"
          x1="0"
          x2="0"
        />
        <text
          class="ruler-text"
          style="font-size: 50px"
          x="-10px"
          [attr.y]="-ruler.length / 2"
          attr.transform="rotate( -90 0 {{ -ruler.length / 2 }} )"
          text-anchor="middle"
        >
          {{ ruler.length.toFixed(0) }}
        </text>
      </g>

      <g
        class="ruler horizontal-ruler"
        *ngIf="!ruler.isVertical"
        attr.transform="translate( {{ ruler.svgStart.X }} {{
          ruler.svgStart.Y
        }} )"
      >
        <line
          class="ruler-line ruler-serif"
          style="stroke: black; stroke-width: 3px"
          x1="0"
          x2="0"
          [attr.y1]="-ruler.serifSize"
          [attr.y2]="ruler.serifSize"
        />
        <line
          class="ruler-line ruler-serif"
          style="stroke: black; stroke-width: 3px"
          [attr.x1]="ruler.length"
          [attr.x2]="ruler.length"
          [attr.y1]="-ruler.serifSize"
          [attr.y2]="ruler.serifSize"
        />
        <line
          class="ruler-line ruler-main-line"
          style="stroke: black; stroke-width: 3px"
          x1="0"
          [attr.x2]="ruler.length"
          y1="0"
          y2="0"
        />
        <text
          class="ruler-text"
          style="font-size: 50px"
          y="-10px"
          [attr.x]="ruler.length / 2"
          text-anchor="middle"
        >
          {{ ruler.length.toFixed(0) }}
        </text>
      </g>
    </g>
    <g *ngFor="let ruler of sectionRulers">
      <g *ngIf="showSectionRulers">
        <g
          class="ruler horizontal-ruler"
          *ngIf="!ruler.isVertical"
          attr.transform="translate( {{ ruler.svgStart.X }} {{
            ruler.svgStart.Y
          }} )"
        >
          <line
            class="ruler-line ruler-serif"
            style="stroke: black; stroke-width: 3px"
            [ngClass]="{ 'custom-size': ruler.isCustom }"
            x1="0"
            x2="0"
            [attr.y1]="-ruler.serifSize"
            [attr.y2]="ruler.serifSize"
          />
          <line
            class="ruler-line ruler-serif"
            style="stroke: black; stroke-width: 3px"
            [ngClass]="{ 'custom-size': ruler.isCustom }"
            [attr.x1]="ruler.length"
            [attr.x2]="ruler.length"
            [attr.y1]="-ruler.serifSize"
            [attr.y2]="ruler.serifSize"
          />
          <line
            class="ruler-line ruler-main-line"
            style="stroke: black; stroke-width: 3px"
            [ngClass]="{ 'custom-size': ruler.isCustom }"
            x1="0"
            [attr.x2]="ruler.length"
            y1="0"
            y2="0"
          />
          <text
            class="ruler-text"
            [attr.class]="{ 'custom-size': ruler.isCustom }"
            style="font-size: 50px"
            y="-10px"
            [attr.x]="ruler.length / 2"
            text-anchor="middle"
          >
            {{ ruler.length.toFixed(0) }}
          </text>
        </g>
      </g>
    </g>
  </g>

  <g class="front-view">
    <rect
      class="cabinet-section-freespace freespace"
      *ngFor="let freespace of freespaces"
      x="0"
      y="0"
      width="0"
      height="0"
      style="fill: url(#freeSpaceFill)"
      [attr.x]="freespace.X"
      [attr.y]="cabinetSection.Height - freespace.Height - freespace.Y"
      [attr.width]="freespace.Width"
      [attr.height]="freespace.Height"
    ></rect>

    <rect
      class="cabinet-section-border"
      x="0"
      y="0"
      width="0"
      height="0"
      style="fill: none; stroke: black; stroke-width: 3px"
      [attr.width]="cabinetSection.interior.cube.Width"
      [attr.height]="cabinetSection.interior.cube.Height"
      [attr.x]="cabinetSection.interior.cube.X"
      [attr.y]="
        cabinetSection.Height -
        cabinetSection.interior.cube.Height -
        cabinetSection.interior.cube.Y
      "
    ></rect>

    <g class="neighbor-info">
      <g
        *ngFor="let neighbor of neighbors"
        class="neighbor-info"
        [attr.class]="neighbor.side"
        attr.transform="translate( {{ neighbor.offset }} 0 ) scale( {{
          neighbor.isMirrored ? -1 : 1
        }} 1 )"
      >
        <rect
          class="cabinet-bounds"
          attr.transform="translate( 0 {{
            cabinetSection.Height -
              neighbor.cabinetBounds.Y -
              neighbor.cabinetBounds.Height
          }} )"
          x="0"
          y="0"
          width="0"
          height="0"
          style="fill: rgba(0, 0, 0, 0.2); stroke: none"
          [attr.x]="neighbor.cabinetBounds.X"
          [attr.width]="neighbor.cabinetBounds.Width"
          [attr.height]="neighbor.cabinetBounds.Height"
        ></rect>

        <rect
          class="interior-bounds"
          attr.transform="translate( 0 {{
            cabinetSection.Height -
              neighbor.interiorBounds.Y -
              neighbor.interiorBounds.Height
          }} )"
          x="0"
          y="0"
          width="0"
          height="0"
          style="fill: rgba(0, 0, 0, 0.2); stroke: none"
          [attr.x]="neighbor.interiorBounds.X"
          [attr.width]="neighbor.interiorBounds.Width"
          [attr.height]="neighbor.interiorBounds.Height"
        ></rect>

        <g
          class="neighbor-item"
          *ngFor="let nItem of neighbor.items"
          [attr.transform]="getNeighborItemTransform(nItem)"
          style="cursor: move"
          (mousedown)="neighborItemMouseDown($event, nItem)"
          (touchstart)="neighborItemMouseDown($event, nItem)"
        >
          <image
            width="0"
            height="0"
            class="item-material-only-fallback"
            href=""
            [attr.href]="nItem.item.Material.ImagePath"
            *ngIf="nItem.item.Material &amp;&amp; nItem.item.Material.ImagePath"
            preserveAspectRatio="none"
            [attr.height]="nItem.Height"
            [attr.width]="nItem.Width"
          />

          <rect
            width="0"
            height="0"
            class="item-no-img-fallback"
            *ngIf="!nItem.item.Material || !nItem.item.Material.ImagePath"
            style="stroke: gray; stroke-width: 2px; fill: rgba(0, 0, 0, 0.01)"
            [attr.height]="nItem.Height"
            [attr.width]="nItem.Width"
          ></rect>

          <rect
            width="0"
            height="0"
            class="item-mouse-area"
            style="fill: rgba(0, 0, 0, 0)"
            [attr.height]="nItem.Height"
            [attr.width]="nItem.Width"
          ></rect>

          <rect
            class="selected-item-outline"
            *ngIf="isItemSelected(nItem.item)"
            x="-5"
            y="-5"
            width="0"
            height="0"
            [attr.width]="nItem.Width + 10"
            [attr.height]="nItem.Height + 10"
          ></rect>

          <rect
            [attr.style]="getItemStyle(nItem.item)"
            x="0"
            y="0"
            width="1"
            height="1"
            [attr.width]="nItem.Width - 2"
            [attr.height]="nItem.Height - 2"
          ></rect>

          <g
            class="item-ruler-overlay"
            *ngIf="showRulers"
            attr.transform="scale( {{ neighbor.isMirrored ? -1 : 1 }} 1 )"
          >
            <rect
              class="item-ruler-background"
              width="0"
              style="fill: rgba(255, 255, 255, 0.5)"
              height="0"
              [attr.width]="itemRulerBackgroundWidth"
              [attr.height]="itemRulerBackgroundHeight"
              [attr.x]="(nItem.Width - itemRulerBackgroundWidth) / 2"
              [attr.y]="(nItem.item.Height - itemRulerBackgroundHeight) / 2"
            ></rect>

            <text
              class="item-ruler-text"
              style="font-size: 40px"
              [attr.x]="nItem.Width / 2"
              [attr.y]="nItem.item.Height / 2 + 13"
              text-anchor="middle"
            >
              {{ nItem.item.PositionNumber }}{{ nItem.item.isGable ? "" : " - "
              + nItem.item.snappedHoleY.toFixed(0) }}
            </text>
          </g>
        </g>
      </g>
    </g>

    <g class="pullout-warnings" *ngIf="showPullout">
      <rect
        class="pullout-warning"
        *ngFor="let pullout of cabinetSection.doors.pulloutWarningAreas"
        [class]="{
          'pullout-severity-info': pullout.severity === 0,
          'pullout-severity-warning': pullout.severity === 1,
          'pullout-severity-critical': pullout.severity === 2,
        }"
        width="0"
        height="0"
        [attr.x]="pullout.start"
        [attr.width]="pullout.end - pullout.start"
        [attr.y]="
          cabinetSection.Height -
          cabinetSection.SightHeight -
          cabinetSection.sightOffsetY
        "
        [attr.height]="cabinetSection.SightHeight"
      ></rect>
    </g>

    <g class="swing-items">
      <g *ngFor="let swingItem of swingItems" class="item">
        <g [app-interior-item]="swingItem" [showWarnings]="false"></g>
      </g>
    </g>

    <g class="swing-flex-items">
      <g *ngFor="let swingFlexItem of swingFlexItems" class="item">
        <g [app-interior-item]="swingFlexItem" [showWarnings]="false"></g>
      </g>
      <g *ngFor="let item of getBackingFittingPanelSpacerCubes()">
        <g [attr.transform]="getBackingFittingPanelTransformString(item)">
          <image
            href=""
            width="0"
            height="0"
            preserveAspectRatio="none"
            [attr.href]="getFittingPanelFillStyle(item)"
            *ngIf="getFittingPanelFillStyle(item)"
            [attr.width]="item.Width"
            [attr.height]="item.Height"
          />
          <rect
            width="0"
            height="0"
            class="item-no-img-fallback"
            *ngIf="!getFittingPanelFillStyle(item)"
            style="
              stroke: gray;
              stroke-width: 2px;
              fill: rgba(255, 255, 255, 0.5);
            "
            [attr.height]="item.Height"
            [attr.width]="item.Width"
          ></rect>
        </g>
      </g>
    </g>

    <g class="front-view-items" style="user-select: none">
      <g *ngFor="let item of getOrderedByGableItems()">
        <g
          class="placed-item item movable-item"
          [app-interior-item]="item"
          *ngIf="displayItem(item)"
          (mouseDown)="itemMouseDown($event, item)"
          (mouseUp)="mouseUp($event)"
        ></g>
      </g>

      <g class="swing-flex-door-items" *ngIf="showSwingFlexDoors">
        <g *ngFor="let item of swingFlexDoorItems">
          <g [app-interior-item]="item" [showStroke]="true"></g>
        </g>
      </g>

      <g *ngFor="let item of getOrderedByGableItems()">
        <g
          [app-interior-item]="item"
          class="placed-item item movable-item"
          *ngIf="displayItem(item) &amp;&amp; !item.isTemplate"
          (mouseDown)="itemMouseDown($event, item)"
          (mouseUp)="mouseUp($event)"
        >
          <rect
            width="0"
            height="0"
            class="item-mouse-area"
            style="fill: rgba(0, 0, 0, 0)"
            [attr.height]="item.mouseClickOffset.Height"
            [attr.width]="item.mouseClickOffset.Width"
            [attr.x]="item.mouseClickOffset.X"
            [attr.y]="item.mouseClickOffset.Y"
            (mouseup)="mouseUp($event)"
          ></rect>
        </g>
      </g>

      <g class="swing-door-hinges" *ngIf="showHinges">
        <g *ngFor="let hinge of hinges">
          <rect
            width="0"
            height="0"
            [attr.style]="hinge.style"
            [attr.height]="hinge.height"
            [attr.width]="hinge.width"
            [attr.x]="hinge.x"
            [attr.y]="hinge.y"
            [attr.transform]="getHingeTransformString(hinge)"
          ></rect>

          <text
            class="hinge-text"
            style="font-size: 40px; pointer-events: none"
            [attr.x]="hinge.x + hinge.width / 2"
            [attr.y]="hinge.y + hinge.height / 2"
            [attr.transform]="getHingeTransformString(hinge)"
            dominant-baseline="central"
            text-anchor="middle"
          >
            {{ hinge.text }}
          </text>
        </g>
      </g>
    </g>

    <g class="corpus" *ngIf="showCorpus" style="pointer-events: none">
      <g class="corpus-items">
        <g *ngFor="let item of corpusItems" class="corpus-item">
          <g [app-interior-item]="item">
            <g class="item-ruler" *ngIf="showRulers">
              <rect
                class="item-ruler-background"
                width="0"
                style="fill: rgba(255, 255, 255, 0.5)"
                height="0"
                [attr.width]="80"
                [attr.height]="itemRulerBackgroundHeight"
                [attr.x]="(item.Width - 80) / 2"
                [attr.y]="(item.Height - itemRulerBackgroundHeight) / 2"
              ></rect>

              <text
                class="item-ruler-text"
                style="font-size: 40px; pointer-events: none"
                [attr.x]="item.Width / 2"
                [attr.y]="item.Height / 2 + 13"
                text-anchor="middle"
              >
                {{ item.PositionNumber }}
              </text>
            </g>
          </g>
        </g>
      </g>
      <g class="corpus-joints">
        <g class="corpus-joint" *ngFor="let joint of corpusJoints">
          <line
            x1="0"
            x2="0"
            y1="0"
            y2="0"
            [attr.y2]="-joint.height"
            attr.transform="translate( {{ joint.X }} {{
              cabinetSection.Height - joint.Y
            }} )"
            style="stroke: black; stroke-width: 10px"
          ></line>
        </g>
      </g>
    </g>

    <g class="item-collision-areas" *ngIf="showWarnings">
      <g
        *ngFor="let item of cabinetSection.interior.items"
        [attr.itemindex]="item.ConfigurationItemIndex"
        class="collision-warning"
      >
        <rect
          *ngFor="let collisionArea of item.collisions"
          [attr.space]="collisionArea.space"
          [attr.severity]="collisionArea.severity"
          width="0"
          height="0"
          [attr.width]="collisionArea.Width"
          [attr.height]="collisionArea.Height"
          [attr.x]="collisionArea.X"
          [attr.y]="
            cabinetSection.Height - collisionArea.Height - collisionArea.Y
          "
          style="
            stroke: none;
            stroke-width: 20px;
            fill: yellow;
            opacity: 0.3;
            pointer-events: none;
          "
        />
      </g>
    </g>

    <g class="item-rulers" *ngIf="showRulers">
      <g *ngFor="let item of cabinetSection.interior.items">
        <g
          class="item-ruler-overlay"
          [app-interior-item]="item"
          [showItem]="false"
          *ngIf="displayItem(item) &amp;&amp; !item.isGrip"
          (mousedown)="itemMouseDown($event, item)"
          (touchstart)="itemMouseDown($event, item)"
        >
          <rect
            class="item-ruler-background"
            width="0"
            style="fill: rgba(255, 255, 255, 0.5); cursor: move"
            height="0"
            [attr.width]="itemRulerBackgroundWidth"
            [attr.height]="itemRulerBackgroundHeight"
            [attr.x]="(item.Width - itemRulerBackgroundWidth) / 2"
            [attr.y]="(item.Height - itemRulerBackgroundHeight) / 2"
          ></rect>

          <text
            class="item-ruler-text"
            style="font-size: 40px; pointer-events: none"
            [attr.x]="item.Width / 2"
            [attr.y]="item.Height / 2 + 13"
            text-anchor="middle"
          >
            {{ item.PositionNumber }}{{ item.isGable ? "" : " - " +
            item.snappedHoleY.toFixed(0) }}
          </text>

          <g *ngIf="item.isGable">
            <rect
              class="item-ruler-background"
              width="40"
              style="fill: rgba(255, 255, 255, 0.5)"
              [attr.x]="item.Width / 2 - 20"
              [attr.y]="item.Height - 70"
              height="40"
            ></rect>
            <text
              class="item-ruler-text"
              style="font-size: 40px"
              [attr.x]="item.Width / 2"
              [attr.y]="item.Height - 30"
              text-anchor="middle"
            >
              {{ item.Width }}
            </text>
          </g>
        </g>
      </g>

      <g
        *ngFor="let item of swingFlexItems"
        [attr.description]="item.Description"
        class="item"
      >
        <g
          *ngIf="shouldDisplayRulerFor(item)"
          class="item-ruler-overlay"
          [app-interior-item]="item"
          [showItem]="false"
          style="pointer-events: none"
        >
          <rect
            class="item-ruler-background"
            width="0"
            style="fill: rgba(255, 255, 255, 0.5)"
            height="0"
            [attr.width]="getItemRulerBackgroundWidth(item)"
            [attr.height]="itemRulerBackgroundHeight"
            [attr.x]="(item.Width - getItemRulerBackgroundWidth(item)) / 2"
            [attr.y]="(item.Height - itemRulerBackgroundHeight) / 2"
          ></rect>

          <text
            class="item-ruler-text"
            style="font-size: 40px; pointer-events: none"
            [attr.x]="item.Width / 2"
            [attr.y]="item.Height / 2 + 13"
            text-anchor="middle"
          >
            {{ getItemRulerString(item) }}
          </text>
        </g>
      </g>
    </g>

    <g class="selected-item-overlays">
      <g *ngFor="let item of getOrderedByGableItems()">
        <g
          *ngIf="isItemSelected(item)"
          [app-interior-item]="item"
          [showItem]="false"
        >
          <rect
            class="selected-item-outline"
            x="-5"
            y="-5"
            width="0"
            height="0"
            [attr.width]="item.Width + 10"
            [attr.height]="item.Height + 10"
          ></rect>

          <g
            *ngIf="item.isGable &amp;&amp; !(item.isFittingPanel || item.isVerticalDivider)"
            attr.transform="translate( {{ item.Width / 2 }} {{
              item.Height - 160
            }} )"
            class="gable-changer"
          >
            <polygon
              points="-30,0 30,0 0,-40"
              (click)="changeGableHeight($event, item, -1)"
              style="cursor: pointer"
              (mousedown)="$event.stopPropagation()"
              (touchstart)="$event.stopPropagation()"
              (mouseup)="$event.stopPropagation()"
              (touchend)="$event.stopPropagation()"
            ></polygon>

            <polygon
              points="-30,10 30,10 0,50"
              (click)="changeGableHeight($event, item, 1)"
              style="cursor: pointer"
              (mousedown)="$event.stopPropagation()"
              (touchstart)="$event.stopPropagation()"
              (mouseup)="$event.stopPropagation()"
              (touchend)="$event.stopPropagation()"
            ></polygon>
          </g>
        </g>
      </g>
    </g>

    <g
      class="snap-info"
      *ngIf="dragInfo &amp;&amp; (dragInfo.type === 'ItemDragInfo' || dragInfo.type === 'NeighborItemDragInfo') "
      [attr.pullout]="dragInfo.snapInfo.pulloutRestriction"
    >
      <g
        *ngFor="let item of dragInfo.snapInfo.snappingItems"
        [app-interior-item]="item"
        [showItem]="false"
      >
        <g
          attr.transform="translate( {{ dragInfo.snapInfo.dropOffset.X }} {{
            -dragInfo.snapInfo.dropOffset.Y
          }} )"
        >
          <rect
            class="item-snap-pos"
            width="0"
            height="0"
            [attr.width]="(dragInfo.snapInfo.newSize &amp;&amp; dragInfo.snapInfo.newSize.X) || item.Width"
            [attr.height]="item.Height"
          ></rect>
        </g>

        <g
          *ngFor="let offset of dragInfo.snapInfo.suggestedItemOffsets"
          attr.transform="translate( {{ offset.X }} {{ -offset.Y }} )"
        >
          <rect
            class="drag-suggested-position"
            width="0"
            height="0"
            [attr.width]="item.Width"
            [attr.height]="item.Height"
          ></rect>
        </g>
      </g>

      <g
        class="drag-support-ruler"
        *ngFor="let ruler of dragInfo.snapInfo.rulers"
      >
        <g [ruler]="ruler"></g>
      </g>

      <g
        class="horizontal-guideline"
        *ngFor="
          let guideline of dragInfo.snapInfo.horizontalGuidelineHeights;
          trackBy: trackByIndex
        "
      >
        <line
          x1="0"
          y1="0"
          x2="0"
          y2="0"
          [attr.x2]="cabinetSection.Width"
          [attr.y1]="cabinetSection.Height - guideline"
          [attr.y2]="cabinetSection.Height - guideline"
          style="stroke: lightblue; stroke-width: 3px"
        ></line>
      </g>

      <g
        class="collision-warning"
        *ngFor="let collisionArea of dragInfo.snapInfo.collisionAreas"
      >
        <rect
          width="0"
          height="0"
          [attr.space]="collisionArea.space"
          [attr.severity]="collisionArea.severity"
          [attr.width]="collisionArea.Width"
          [attr.height]="collisionArea.Height"
          [attr.x]="collisionArea.X"
          [attr.y]="
            cabinetSection.Height - collisionArea.Height - collisionArea.Y
          "
          style="stroke: none; stroke-width: 20px; fill: yellow; opacity: 0.3"
        ></rect>
      </g>
    </g>

    <rect
      class="selection-rectangle"
      width="0"
      height="0"
      *ngIf="dragInfo &amp;&amp; dragInfo.type === 'RectangleSelectInfo'"
      [attr.x]="dragInfo.topLeft.X"
      [attr.y]="svgHeight(dragInfo.topLeft.Y)"
      [attr.width]="dragInfo.size.X"
      [attr.height]="dragInfo.size.Y"
    />
  </g>

  <g class="topdown-view" *ngIf="showRulers">
    <g
      class="sliding-doors"
      attr.transform="translate({{ topDownPosX }} {{ topDownPosY }})"
    >
      <g
        class="door-topdown-group"
        *ngFor="let door of doors"
        attr.transform="translate({{ door.position.X }} {{ door.position.Z }})"
      >
        <rect
          class="door-topdown"
          x="0"
          y="0"
          width="0"
          height="0"
          style="fill: #111; stroke: #666; stroke-width: 3px"
          [attr.width]="door.size.X"
          [attr.height]="door.size.Z"
        ></rect>

        <text
          style="font-size: 40px"
          x="0"
          y="-10"
          [attr.x]="door.size.X / 2"
          text-anchor="middle"
        >
          {{ roundUp(door.size.X) }}
        </text>
      </g>
    </g>
  </g>
  <g
    class="swing-door-view"
    attr.transform="translate(0, {{ cabinetSection.Height + 10 }})"
    *ngIf="showSwingDoors"
  >
    <g *ngFor="let door of swingDoors">
      <line
        x1="0"
        y1="0"
        x2="0"
        y2="0"
        [attr.x1]="door.pos1.X"
        [attr.y1]="door.pos1.Y"
        [attr.x2]="door.pos2.X"
        [attr.y2]="door.pos2.Y"
        stroke="black"
        style="stroke-width: 19"
      ></line>
    </g>
  </g>
</svg>
