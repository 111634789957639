<svg:g>
  <!-- This file has been deprecated. To insert a ruler, copy the following into your .svg template. -->

  <g
    class="ruler vertical-ruler"
    [class]="{ 'custom-size': ruler.isCustom }"
    *ngIf="ruler.isVertical"
    attr.transform="translate( {{ ruler.svgStart.X }} {{ ruler.svgStart.Y }} )"
  >
    <line
      class="ruler-line ruler-serif"
      style="stroke: black; stroke-width: 3px"
      [attr.x1]="-ruler.serifSize"
      [attr.x2]="ruler.serifSize"
      y1="0"
      y2="0"
    />
    <line
      class="ruler-line ruler-serif"
      style="stroke: black; stroke-width: 3px"
      [attr.x1]="-ruler.serifSize"
      [attr.x2]="ruler.serifSize"
      [attr.y1]="-ruler.length"
      [attr.y2]="-ruler.length"
    />
    <line
      class="ruler-line ruler-main-line"
      style="stroke: black; stroke-width: 3px"
      y1="0"
      [attr.y2]="-ruler.length"
      x1="0"
      x2="0"
    />
    <text
      class="ruler-text"
      style="font-size: 50px"
      [attr.x]="textX"
      [attr.y]="textY"
      transform="rotate( -90 0 0 )"
      text-anchor="middle"
    >
      {{ ruler.length.toFixed(0) }}
    </text>
  </g>

  <g
    class="ruler horizontal-ruler"
    [class]="{ 'custom-size': ruler.isCustom }"
    *ngIf="!ruler.isVertical"
    attr.transform="translate( {{ ruler.svgStart.X }} {{ ruler.svgStart.Y }} )"
  >
    <line
      class="ruler-line ruler-serif"
      style="stroke: black; stroke-width: 3px"
      x1="0"
      x2="0"
      [attr.y1]="-ruler.serifSize"
      [attr.y2]="ruler.serifSize"
    />
    <line
      class="ruler-line ruler-serif"
      style="stroke: black; stroke-width: 3px"
      [attr.x1]="ruler.length"
      [attr.x2]="ruler.length"
      [attr.y1]="-ruler.serifSize"
      [attr.y2]="ruler.serifSize"
    />
    <line
      class="ruler-line ruler-main-line"
      style="stroke: black; stroke-width: 3px"
      x1="0"
      [attr.x2]="ruler.length"
      y1="0"
      y2="0"
    />
    <text
      class="ruler-text"
      style="font-size: 50px"
      [attr.x]="textX"
      [attr.y]="textY"
      text-anchor="middle"
    >
      {{ ruler.length.toFixed(0) }}
    </text>
  </g>
</svg:g>
